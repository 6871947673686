/**
 * makers-paradise 主体内容 - 移动端
 */
import React from 'react';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import Accordion from './accordion';

export default function MakersParadiseContentMobile({ images, content }) {
  // 面板数量
  const panelCount = images.length || content.length || 0;

  return (
    <Accordion direction="vertical" panelCount={panelCount} content={content} wrapperClsNames="h-[640px] w-full" inactivePanelClsNames={'flex-grow-[0.7]'}>
      <Content images={images} content={content} />
    </Accordion>
  );
}

function Content({
  activeIndex, currentIndex, images, content,
}) {
  const active = activeIndex === currentIndex;

  return (
    <IntlContextConsumer>
      {
        ({ language }) => {
          const isEn = language === 'en';

          return (<div className={`hoverPhoto ${isEn ? 'en' : 'zh'}`}>
            {!!images.length && <img className="absolute top-0 left-0 w-full h-full object-cover" src={images[currentIndex]} alt="img" />}
            <div className={`absolute bottom-0 left-0 w-full h-full ${active ? 'bg-none items-start flex-col' : 'bg-black bg-cover items-center bg-opacity-60'} flex justify-end p-4`}>
              <div className="w-full" dangerouslySetInnerHTML={{ __html: content[currentIndex].title }} />
              <div className={`${active ? 'block' : 'hidden'} mt-2`} dangerouslySetInnerHTML={{ __html: content[currentIndex].info }} />
              { !active && <span className="iconfont iconarrow_down !text-xl font-bold"></span>}
            </div>
          </div>);
        }
      }
    </IntlContextConsumer>
  );
}
