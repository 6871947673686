/**
 * 创造即奖励，有回报、有成长、有成就、有意义
 */
import React from 'react';
import MakersAwardContent from './makers-award-content';
import SectionTitle from './section-title';
import MakersAwardContentMobile from './makers-award-content-mobile';
import useDeviceType from '../../hooks/useDeviceType';
import ScrollDrivenAnimation from './scroll-driven-animation';

export default function MakersAward({
  title, subTitle, image, content,
}) {
  const isMobile = useDeviceType();
  const images = isMobile ? image.mobile : image.pc;

  return (
  // <ScrollDrivenAnimation>
      <div className="makersAward max-w-[1200px] m-auto px-6 xl:px-0">
        <SectionTitle title={title} subTitle={subTitle} />
        { isMobile ? <MakersAwardContentMobile images={images} content={content} />
          : <MakersAwardContent images={images} content={content} />}
      </div>
  // </ScrollDrivenAnimation>
  );
}
