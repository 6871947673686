import React, { useState, useCallback, useEffect } from "react";
import SectionTitle from './section-title';
// import ScrollDrivenAnimation from "./scroll-driven-animation";

const AnkerFind = (props) => {
  // console.log("temp-AnkerFind", props);
  const [isMobile, setIsMobile] = useState(false);
  const [isEnWebsite, setIsEnWebsite] = useState(false);

  useEffect(() => {
    if (props.location.pathname.indexOf('/en') > -1) {
      setIsEnWebsite(true);
    } else {
      setIsEnWebsite(false);
    }
  }, [props.location.pathname]);

  const setMobile = useCallback(() => setIsMobile(window?.innerWidth < 768), []);

  useEffect(() => {
    setMobile();
    window.addEventListener("resize", setMobile);
    return () => window.removeEventListener("resize", setMobile);
  }, [setMobile]);

  return (
    <div className="ankerFind">
      {/* <ScrollDrivenAnimation> */}
      <div className="bg-black relative px-6 xl:px-0">
        <section className="overflow-auto bg-black relative z-10">
          <div style={{ opacity: 1, transform: 'scale(1)' }}>
            <div className="pt-4 md:pt-[2%]">
              <div className="w-full">
                <div className="mx-auto box-border xl:max-w-screen-xl ">
                  <SectionTitle title={props.title} />
                </div>
              </div>
              {!isMobile ? <div className="w-full md:max-w-[1200px] lg:max-w-[1200px] xl:max-w-[1200px] 2xl:max-w-[1200px] mx-auto flex">
                <div className="mr-[16px] w-[592px]">
                  <div className="relative z-[1] w-full mb-[16px] group overflow-hidden h-[cala(50%-8px)]">
                    <img src={props?.list[0]?.image} alt="" className="relative z-[1] w-full max-w-full h-full object-cover group-hover:scale-110 transition-transform duration-500" />
                    <div className="absolute z-[2] inset-0 p-6 overflow-hidden">
                      <p className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[#F5F5F7] text-[24px] md:text-[24px] lg:text-[24px] xl:text-[24px] 2xl:text-[24px] leading-[28px]`}>{props?.list[0]?.title}</p>
                      <p style={{ maxHeight: '200px', overflowY: 'auto' }} className={`${isEnWebsite ? 'pr-[48%]' : 'pr-[66%]'} font-[300] mt-6 text-[#F5F5F7] text-[16px] md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[16px] leading-[24px]`}>{props?.list[0]?.desc}</p>
                    </div>
                  </div>

                  <div className="relative z-[1] w-full group overflow-hidden h-[cala(50%-8px)]">
                    <img src={props?.list[1]?.image} alt="" className="relative z-[1] w-full max-w-full h-full object-cover group-hover:scale-110 transition-transform duration-500" />
                    <div className="absolute z-[2] inset-0 p-6 overflow-hidden">
                      <p className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[#F5F5F7] text-[24px] md:text-[24px] lg:text-[24px] xl:text-[24px] 2xl:text-[24px] leading-[28px]`}>{props?.list[1]?.title}</p>
                      <p style={{ maxHeight: '200px', overflowY: 'auto' }} className={`${isEnWebsite ? 'pr-[48%]' : 'pr-[66%]'} mt-6 text-[#F5F5F7] font-[300] text-[16px] md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[16px] leading-[24px]`}>{props?.list[1]?.desc}</p>
                    </div>
                  </div>
                </div>
                <div className="relative group overflow-hidden w-[592px]">
                  <img src={props?.list[2]?.image} alt="" className="relative z-[1] w-full max-w-full h-full object-cover group-hover:scale-110 transition-transform duration-500" />
                  <div className="absolute z-[2] inset-0 p-6 overflow-hidden">
                    <p className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[#F5F5F7] text-[24px] md:text-[24px] lg:text-[24px] xl:text-[24px] 2xl:text-[24px] leading-[28px]`}>{props?.list[2]?.title}</p>
                    <p style={{ maxHeight: '460px', overflowY: 'auto' }} className="w-full mt-6 text-[#F5F5F7] font-[300] text-[16px] md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[16px] leading-[24px]">{props?.list[2]?.desc}</p>
                  </div>
                </div>
              </div> : <>
                {props?.list?.length > 0 && props?.list?.map((item, index) => (
                  <div key={index} className="relative z-[1] w-full mb-2 group overflow-hidden h-auto">
                    <img src={item.mobileimage} alt="" className="relative z-[1] w-full h-full object-cover group-hover:scale-110 transition-transform duration-500" />
                    {/* <div className={`absolute z-[2] inset-0 p-5 overflow-hidden ${index === 2 && 'items-start flex-col flex justify-end'}`}> */}
                    <div className={`absolute z-[2] inset-0 overflow-hidden ${isEnWebsite ? 'p-4 md:p-5' : 'p-5'}`}>
                      <p className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[#F5F5F7] text-[20px] md:text-[20px] lg:text-[20px] xl:text-[20px] 2xl:text-[20px] leading-[28px]`}>{item.title}</p>
                      <p className={`${index !== 2 ? (isEnWebsite ? 'pr-[14%]' : 'pr-[48%]') + (isMobile ? ' max-h-[80px]' : ' max-h-[120px]') + ' overflow-y-auto' : 'w-full max-h-[300px] overflow-y-auto'} mt-4 text-[#F5F5F7]  ${isEnWebsite ? 'font-[300] md:font-[300] leading-[16px] md:leading-[18px]' : 'font-[300] leading-[18px]'} text-[12px] md:text-[12px] lg:text-[12px] xl:text-[12px] 2xl:text-[12px]`}>{item.desc}</p>
                    </div>
                  </div>
                ))}
              </>}
            </div>
          </div>
        </section>
      </div>
      {/* </ScrollDrivenAnimation> */}

    </div>
  );
};

export default AnkerFind;