/**
 * 主页模块标题栏
 */
import React from 'react';
import { IntlContextConsumer } from 'gatsby-plugin-intl';

const SectionTitle = ({ title, subTitle }) => (
  <IntlContextConsumer>
    {
      ({ language }) => {
        const isEn = language === 'en';

        return (
          <div className="section-title text-white max-w-[1200px] m-auto mt-20 lg:mt-40 mb-8 lg:mb-16">
            <p className={`${isEn ? 'font-bold' : 'font-semibold'} text-2xl md:text-3xl lg:text-[40px] lg:leading-[48px]`} dangerouslySetInnerHTML={{ __html: title }} />
            {subTitle && <p className="text-sm text-[#B6B6BA] mt-4 lg:mt-6 md:text-base lg:text-xl font-light" dangerouslySetInnerHTML={{ __html: subTitle }}></p>}
          </div>
        );
      }
    }
  </IntlContextConsumer>
);

export default SectionTitle;
