import React, { useEffect, useState, useCallback } from "react";
import { motion } from 'framer-motion';
import * as styles from './anker-story.module.scss';

const Introduce = (props) => {
  const [isMobile, setIsMobile] = useState(false);

  const setMobile = useCallback(() => setIsMobile(window?.innerWidth < 768), []);
  useEffect(() => {
    setMobile();
    window.addEventListener("resize", setMobile);
    return () => window.removeEventListener("resize", setMobile);
  }, [setMobile]);

  const [isEnWebsite, setIsEnWebsite] = useState(false);
  useEffect(() => {
    if (props.location.pathname.indexOf('/en') > -1) {
      setIsEnWebsite(true);
    } else {
      setIsEnWebsite(false);
    }
  }, [props.location.pathname]);

  const [isExpanded, setIsExpanded] = useState(false);
  const handleExpand = () => {
    setIsExpanded(true);
  };

  return <div className="px-6 w-full mx-auto box-border text-white max-w-[1200px] m-auto mt-20 lg:mt-40 mb-8 lg:mb-16 text-center">
    <motion.div initial={{ opacity: 0, translateY: 64 }} whileInView={{ opacity: 1, translateY: 0 }} transition={{ duration: 0.5 }}>
      <div className={`${isEnWebsite ? 'font-[700]' : 'font-[600]'} text-[24px] md:text-[40px] leading-[32px] lg:leading-[48px] color-[#F5F5F7]`}>{props.title}</div>
      <div className={`${isEnWebsite ? 'font-[300] leading-[22px] md:leading-[24px]' : 'font-[300] leading-[24px]'}  text-[#B6B6BA] md:pt-[24px] lg:pt-[24px] xl:pt-[34px] 2xl:pt-[34px] text-[14px] md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[16px] ${!isMobile ? 'md:max-h-[calc(100%-102px)] lg:max-h-[calc(100%-102px)] xl:max-h-[calc(100%-112px)] 2xl:max-h-[calc(100%-112px)] overflow-y-auto' : ''} ${styles.introduceDesc}`}>
        {isMobile ? props.mobiledesc.map((item, index) => (
          <React.Fragment key={index}>
            {index < 3 && <p dangerouslySetInnerHTML={{ __html: item }} />}
            {isExpanded && index > 2 && <p dangerouslySetInnerHTML={{ __html: item }} />}
          </React.Fragment>
        )) : props.desc.map((item, index) => (
          <p key={index} dangerouslySetInnerHTML={{ __html: item }} />
        ))}
        {isMobile && !isExpanded && props.mobiledesc.length > 3 && <div className={`${isEnWebsite ? 'font-[300]' : 'font-[300]'} mt-2 text-[14px] leading-[18px] text-[#F5F5F7]`} onClick={handleExpand}><u>{isEnWebsite ? 'Learn More' : '更多'}</u></div>}
      </div>
    </motion.div>
  </div>;
};

export default Introduce;