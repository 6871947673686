/**
 * 主页内容区域第四部分 - 为什么我们是Makers创造者的乐园？
 */
import React from 'react';
import SectionTitle from './section-title';
import useDeviceType from '../../hooks/useDeviceType';
import MakersParadiseContentMobile from './makers-paradise-content-mobile';
import ScrollDrivenAnimation from './scroll-driven-animation';
import MakersParadiseContent from './makers-paradise-content';
import './makers-paradise.scss';

export default function MakersParadise({
  title, subTitle, image, content,
}) {
  const isMobile = useDeviceType();
  const images = isMobile ? image.mobile : image.pc;

  return (
  // <ScrollDrivenAnimation>
      <div className="makersParadise max-w-[1200px] m-auto px-6 xl:px-0">
        <SectionTitle title={title} subTitle={subTitle} />
        {isMobile
          ? (
            <MakersParadiseContentMobile
              images={images}
              content={content}
            />
          ) : (
            <MakersParadiseContent
              images={images}
              content={content}
            />
          )}
      </div>
  // </ScrollDrivenAnimation>
  );
}
