/**
 * accordion 组件内容面板
 */
import React from 'react';
import Slot from '../common/slot';

export default function AccordionPanel({
  active, classNames, activePanelClsNames, inactivePanelClsNames, children, onActiveChange,
}) {
  return (
    <div className={`accordion-panel ${classNames} ${active ? activePanelClsNames : inactivePanelClsNames}`} onMouseEnter={onActiveChange}>
      <Slot>{children}</Slot>
    </div>
  );
}
