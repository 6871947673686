/**
 * 插槽组件
 */
import React from 'react';

export default function Slot({ name, children, scope }) {
  return React.Children.map(children, (child) => {
    if (child && child.props?.slot === name) {
      return React.cloneElement(child, { ...scope, ...child.props });
    }
    return null;
  });
}
