/**
 * makers-paradise 主体内容
 */
import React from 'react';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import Accordion from './accordion';

export default function MakersParadiseContent({ images, content }) {
  // 面板数量
  const panelCount = images.expand?.length || content.length || 0;

  return (
    <Accordion
      panelCount={panelCount}
      wrapperClsNames="w-full h-[632px]"
      inactivePanelClsNames="flex-grow-[1.5]"
    >
      <Content images={images} content={content} />
    </Accordion>
  );
}

function Content({
  activeIndex, currentIndex, images, content,
}) {
  // 当前面板是否展开
  const active = activeIndex === currentIndex;
  const imageStatusKey = active ? 'expand' : 'collapse';

  const titleHtml = active ? content[currentIndex].title : content[currentIndex].title.replace(/[,，]/g, ',<br/>');

  return (
    <IntlContextConsumer>
      {
        ({ language }) => {
          const isEn = language === 'en';

          return (<div className={`hoverPhoto ${isEn ? 'en' : 'zh'}`}>
            {!!images[imageStatusKey]?.length && <img className="absolute top-0 left-0 w-full h-full object-cover" src={images[imageStatusKey][currentIndex]} alt="img" />}
            <div className={`absolute top-0 left-0 w-full h-full ${active ? 'items-start flex-col' : ''} bg-none flex justify-between px-4 py-6 lg:px-8 lg:py-10`}>
              <div className={active ? 'animate-fadeIn' : 'block'} dangerouslySetInnerHTML={{ __html: titleHtml }} />
              <div className={active ? 'animate-fadeIn' : 'opacity-0 hidden'} dangerouslySetInnerHTML={{ __html: content[currentIndex].info }} />
            </div>
          </div>);
        }
      }
    </IntlContextConsumer>
  );
}
