import React from 'react';
import { Drawer } from 'antd';
import {
  CloseOutlined,
} from '@ant-design/icons';
import SectionTitle from './section-title';
// import ScrollDrivenAnimation from "./scroll-driven-animation";
import LinkButton from '../LinkButton';

const RecruitingProcess = (props) => {
  console.log('temp-RecruitingProcess', props);
  const { RecruitingProcess, faq } = props;
  const [open, setOpen] = React.useState(false);
  const [isEnWebsite, setIsEnWebsite] = React.useState(false);

  React.useEffect(() => {
    if (props.location.pathname.indexOf('/en') > -1) {
      setIsEnWebsite(true);
    } else {
      setIsEnWebsite(false);
    }
  }, [props.location.pathname]);

  const linkClick = (index) => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };
  return (
    <div className="RecruitingProcess">
      <Drawer
        title="Basic Drawer"
        placement='bottom'
        height='70vh'
        onClose={onClose}
        open={open}
        headerStyle={{ display: 'none' }}
        drawerStyle={{ backgroundColor: '#1D1D1F', border: 'none' }}
        bodyStyle={{
          padding: 0, backgroundColor: '#1D1D1F', border: 'none', overflow: 'hidden', borderRadius: '12px',
        }}
      >
        <div className="flex justify-end items-center text-white"><CloseOutlined onClick={onClose} className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} px-4 py-4 text-[#979797] text-[16px] md:text-[16px] lg:text-[20px] xl:text-[24px] 2xl:text-[28px]`} /></div>
        <div className="h-[calc(70vh-64px)] overflow-y-auto">
          {faq && faq.map((item, index) => (
            <div key={index} className="w-full px-6 md:px-10 pb-10">
              <div className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[#F5F5F7] text-[26px] md:text-[26px] lg:text-[28px] xl:text-[30px] 2xl:text-[32px] leading-[1.5] mb-4`}>{item.title}</div>
              {item.list && item.list.map((questionItem, questionIndex) => (
                <div key={questionIndex} className="pb-3" style={{ borderBottom: '1px solid #3D3D3D' }}>
                  <div className={`${isEnWebsite ? 'font-[600]' : 'font-[500]'} text-[#F5F5F7] text-[16px] md:text-[16px] lg:text-[16px] xl:text-[18px] 2xl:text-[20px] leading-[1.2] mt-3`}>{questionItem.question}</div>
                  <div className="font-[300] text-[#B6B6BA] text-[14px] md:text-[14px] lg:text-[14px] xl:text-[16px] 2xl:text-[16px] leading-[1.4] mt-2" dangerouslySetInnerHTML={{ __html: questionItem.answer }} />
                </div>
              ))}
            </div>
          ))}
        </div>
      </Drawer>
      {/* <ScrollDrivenAnimation> */}
      <div className="bg-black relative px-6 xl:px-0">
        <section className="overflow-auto bg-black relative z-10">
          <div style={{ opacity: 1, transform: 'scale(1)' }}>
            <div className="pt-4 md:pt-[2%]">
              <div className="w-full">
                <div className="mx-auto box-border xl:max-w-screen-xl ">
                  <SectionTitle title={RecruitingProcess.title} />
                </div>
              </div>
              <div className="z-[1] hidden md:block lg:block xl:block 2xl:block w-[80%] max-w-[1000px] mx-auto bg-[#CECECF] h-[1px] mt-[120px]"></div>
              {/* md:mt-[28px] lg:mt-[28px] xl:mt-[40px] 2xl:mt-[40px] */}
              <div className="mx-auto w-full md:max-w-[1200px] lg:max-w-[1200px] xl:max-w-[1200px] 2xl:max-w-[1200px] flex flex-wrap justify-center mt-[28px] md:-mt-[43px] lg:-mt-[54px] xl:-mt-[66px] 2xl:-mt-[78px] z-[2]">
                {RecruitingProcess.list && RecruitingProcess.list.length && RecruitingProcess.list.map((item, index) => (
                  <div key={index} className="w-full md:w-[25%] lg:w-[25%] xl:w-[25%] 2xl:w-[25%] mb-8 md:mb-[0] lg:mb-[0] xl:mb-[0] 2xl:mb-[0] last:mb-0">
                    <div className="md:w-[66%] md:bg-black p-[26px] md:p-[26px] lg:p-[34px] xl:p-[42px] 2xl:p-[50px] mx-auto flex justify-center items-center text-white text-[800] leading-[1.2] text-[28px] md:text-[28px] lg:text-[32px] xl:text-[40px] 2xl:text-[46px]" style={{
                      backgroundImage: 'url(https://en.anker-in.com/wp-content/uploads/2025/02/recruitingprocess-bg.png)', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat',
                    }}>
                      <div className="">{index + 1}</div>
                    </div>
                    <div className={`w-[90%] ${isEnWebsite ? 'font-[600]' : 'font-[500]'} mx-auto text-white text-[20px] md:text-[24px] lg:text-[24px] xl:text-[24px] 2xl:text-[24px] text-center mt-6 leading-[28px] md:leading-[32px]`}>{item.title}</div>
                    <div className="w-[90%] font-[300] mx-auto text-[14px] md:text-[16px] lg:text-[16px] xl:text-[16px] 2xl:text-[16px] text-center mt-3 leading-[18px] md:leading-[24px] text-[#B6B6BA]">{item.desc}</div>
                  </div>
                ))}
              </div>
              <div className="flex flex-wrap w-full justify-center mt-[32px] md:mt-[32px] lg:mt-[32px] xl:mt-[54px] 2xl:mt-[54px]">
                {RecruitingProcess.buttomList.map((item, index) =>
                  <span key={index}>
                    <LinkButton item={item} theme='white' handleLinkClick={() => linkClick(index)} />
                  </span>)}
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* </ScrollDrivenAnimation> */}
    </div>
  );
};

export default RecruitingProcess;
