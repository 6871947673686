import React, { useEffect, useState, useCallback } from "react";
// import SectionTitle from './section-title';
// import ScrollDrivenAnimation from "./scroll-driven-animation";
import LinkButton from "../LinkButton";

const JoinUs = (props) => {
  const [isMobile, setIsMobile] = useState(false);

  const setMobile = useCallback(() => setIsMobile(window?.innerWidth < 768), []);

  useEffect(() => {
    setMobile();
    window.addEventListener("resize", setMobile);
    return () => window.removeEventListener("resize", setMobile);
  }, [setMobile]);
  // console.log("temp-JoinUs", props);
  return (
    <div className="JoinUs">
      {/* <ScrollDrivenAnimation> */}
      <div className="bg-black">
        <section className="overflow-auto bg-black relative z-10">
          <div style={{ opacity: 1, transform: 'scale(1)' }}>
            <div className="py-[4.6rem] md:py-[6%]" style={{ backgroundImage: `url(${isMobile ? props.mobilebg : props.pcbg})`, backgroundSize: 'cover', backgroundPosition: 'center', backgroundRepeat: 'no-repeat', }}>
              <div className="font-[500] text-center text-white text-[16px] md:text-[16px] lg:text-[18px] xl:text-[20px] 2xl:text-[24px] leading-[1.1em] max-w-[1200px] mx-auto px-6 xl:px-0">{props.title}</div>
              <div className="text-center font-[700] text-white text-[24px] md:text-[40px] lg:text-[40px] xl:text-[40px] 2xl:text-[40px] leading-[1.5em] mt-[4px] md:mt-[4px] lg:mt-[4px] xl:mt-[8px] 2xl:mt-[8px] max-w-[1200px] mx-auto px-6 xl:px-0">{props.subtitle}</div>
              {props.buttomList && props.buttomList.length && (

                <div className={`flex flex-wrap w-full justify-center mt-[28px] md:mt-[28px] lg:mt-[28px] xl:mt-[40px] 2xl:mt-[40px] px-6 xl:px-0`}>
                  {props.buttomList.map((item, index) =>
                    <span key={index}>
                      <LinkButton item={item} theme='white' />
                    </span>
                  )}
                </div>
              )}
              {/* <div className="px-3 md:px-4 lg:px-6 w-full">
                  <div className="mx-auto box-border xl:max-w-screen-xl ">
                    <SectionTitle title={`<div class="text-white font-bold text-[32px] xl:text-[40px] 2xl:text-[56px] max-w-[848px] leading-tight">${props.title}</div>`} />
                  </div>
                </div> */}
            </div>
          </div>
        </section>
      </div>
      {/* </ScrollDrivenAnimation> */}
    </div>
  );
};

export default JoinUs;