/**
 * makers-award 主体内容 - 移动端
 */
import React from 'react';
import { range } from 'lodash';
import './makers-award.scss';
import { IntlContextConsumer } from 'gatsby-plugin-intl';

export default function MakersAwardContentMobile({ images = [], content = [] }) {
  const panelCount = images.length || content.length || 0;

  return (
    <IntlContextConsumer>
      {
        ({ language }) => {
          const isEn = language === 'en';

          return (
            <>
              {
                range(panelCount).map((i) => (
                  <div key={i} className={`text-white w-full h-full ${i < panelCount - 1 ? 'mb-2' : ''} hoverPhoto ${isEn ? 'en' : 'zh'}`}>
                    <img className="w-full" src={images[i]} alt="img" />
                    <div className="bg-[#1D1D1F] px-4 py-6">
                      <div dangerouslySetInnerHTML={{ __html: content[i].title }} />
                      <div dangerouslySetInnerHTML={{ __html: content[i].info }} />
                    </div>
                  </div>
                ))
              }
            </>
          );
        }
      }
    </IntlContextConsumer>
  );
}
