/**
 * makers-values 主体内容 - 移动端
 */
import React, { useState, useRef, useEffect } from 'react';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import Accordion from './accordion';

export default function MakersValuesContentMobile({ images = {}, content = [] }) {
  // 面板数量
  const panelCount = images.expand?.length || content.length || 0;

  return (
    <IntlContextConsumer>
      {
        ({ language }) => (
          <Accordion direction="vertical" panelCount={panelCount} content={content} wrapperClsNames={`${language === 'en' ? 'h-[881px]' : 'h-[760px]'} w-full`} inactivePanelClsNames={'flex-grow-[0.28]'}>
            <Content images={images} content={content} />
          </Accordion>
        )
      }
    </IntlContextConsumer>
  );
}

function Content({
  activeIndex, currentIndex, images, content,
}) {
  const active = activeIndex === currentIndex;
  const imageStatusKey = active ? 'expand' : 'collapse';

  const [isExpanded, setIsExpanded] = useState(false);
  const [isOverflowing, setIsOverflowing] = useState(false);
  const contentRef = useRef(null);
  const showLearnMore = !isExpanded && isOverflowing;

  useEffect(() => {
    if (contentRef.current) {
      const checkOverflow = () => {
        setIsOverflowing(contentRef.current.scrollHeight > contentRef.current.clientHeight);
      };

      // 使用 ResizeObserver 监听元素尺寸变化
      const resizeObserver = new ResizeObserver(() => {
        checkOverflow();
      });

      resizeObserver.observe(contentRef.current);
      // 初始检查
      checkOverflow();

      // 清除事件监听器
      return () => {
        resizeObserver.disconnect();
      };
    }
  }, [activeIndex]);

  const handleExpand = () => {
    setIsExpanded(true);
  };

  return (
    <IntlContextConsumer>
      {
        ({ language }) => {
          const isEn = language === 'en';
          const learnMoreText = isEn ? 'Learn More' : '查看更多';
          const containerWidthClsWithLearnMore = isEn ? 'max-h-[360px]' : 'max-h-[242px]';
          const containerWidthClsWithoutLearnMore = isEn ? 'max-h-[390px]' : 'max-h-[272px]';

          return (
            <div className={`hoverPhoto ${isEn ? 'en' : 'zh'}`}>
              {!!images[imageStatusKey]?.length && <img className="absolute top-0 left-0 w-full h-full object-cover" src={images[imageStatusKey][currentIndex]} alt="img" />}
              <div className={`absolute bottom-0 left-0 w-full h-full ${active ? 'bg-none flex-col' : ''} flex items-center justify-end p-4`}>
                <div className="w-full">
                  <div dangerouslySetInnerHTML={{ __html: content[currentIndex].title }} />
                  {content[currentIndex].subTitle && <div className={`${active ? 'block' : 'hidden'}`} dangerouslySetInnerHTML={{ __html: content[currentIndex].subTitle }} />}
                </div>
                <div className={`${active ? 'animate-fadeIn' : 'opacity-0 hidden'}`}>
                  <div ref={contentRef} className={`w-full ${showLearnMore ? containerWidthClsWithLearnMore : containerWidthClsWithoutLearnMore} ${isExpanded ? 'overflow-y-auto' : 'overflow-y-hidden'}`} dangerouslySetInnerHTML={{ __html: content[currentIndex].info }} />
                  {showLearnMore && <div className={`learn-more-underline cursor-pointer ${isEn ? 'text-xs' : 'text-sm'} mt-3`} onClick={handleExpand}>{learnMoreText}</div>}
                </div>
                { !active && <span className="iconfont iconarrow_down !text-xl font-bold"></span>}
              </div>
            </div>
          );
        }
      }
    </IntlContextConsumer>
  );
}
