/**
 * innovative-products 核心内容
 */
import React from 'react';
import { ReactSVG } from 'react-svg';
import { IntlContextConsumer } from 'gatsby-plugin-intl';
import Accordion from './accordion';
import useDeviceType from '../../hooks/useDeviceType';
import AnkerLogo from '../../images/anker-logo.svg';
import EufyLogo from '../../images/eufy-logo.svg';
import SoundcoreLogo from '../../images/soundcore-logo.svg';
import AnkerMobileLogo from '../../images/anker-mobile-logo.svg';
import EufyMobileLogo from '../../images/eufy-mobile-logo.svg';
import SoundcoreNobileLogo from '../../images/soundcore-mobile-logo.svg';

export default function InnovativeProductsContent({ images, content, comment }) {
  // 面板数量
  const panelCount = images.length || content.length || 0;
  const isMobile = useDeviceType();

  const wrapperClsNames = `w-full ${isMobile ? 'h-[572px]' : 'h-[682px]'}`;
  const inactivePanelClsNames = isMobile ? 'flex-grow-[0.5]' : 'flex-grow-[0.9]';

  const logos = isMobile
    ? [AnkerMobileLogo, EufyMobileLogo, SoundcoreNobileLogo]
    : [AnkerLogo, EufyLogo, SoundcoreLogo];

  return (
    <>
      <Accordion direction="vertical" wrapperClsNames={wrapperClsNames} panelCount={panelCount} inactivePanelClsNames={inactivePanelClsNames}>
        <Content images={images} content={content} logos={logos} />
      </Accordion>
      <div className="mt-3 xl:mt-4 text-xs xl:text-sm text-[#86868C] font-light">{comment}</div>
    </>
  );
}

function Content({
  activeIndex, currentIndex, images, content, logos,
}) {
  const active = activeIndex === currentIndex;
  return (
    <IntlContextConsumer>
      { ({ language }) => {
        const isEn = language === 'en';

        return (
          <div className={`hoverPhoto ${isEn ? 'en' : 'zh'}`}>
            {!!images.length && <img className="absolute top-0 left-0 w-full h-full object-cover" src={images[currentIndex]} alt="img" />}
            <div className={`absolute top-0 left-0 w-full h-full ${active ? 'bg-none items-start flex-col' : 'bg-black bg-cover items-center bg-opacity-60'} flex justify-between px-4 pt-6 pb-4 lg:px-8 lg:py-10`}>
              <ReactSVG src={logos[currentIndex]} />
              <div className={`w-full max-w-[492px] ${active ? 'block' : 'hidden'}`} dangerouslySetInnerHTML={{ __html: content[currentIndex].info }} />
              { !active && <span className="iconfont iconarrow_down !text-xl font-bold"></span>}
            </div>
          </div>
        );
      }
      }
    </IntlContextConsumer>
  );
}
